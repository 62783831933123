import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import VideoController from '../lib/VideoController';

export default (el, props) => {
    const $el = $(el);
    const link = $el.find('[data-link]');
    const videoContainer = $el.find('[data-embed-wrap]');
    
    let controller;
    let videoInited = false;

    const close = () => {
        gsap.killTweensOf(link.get(0));
        document.removeEventListener('keyup', keyup);
        gsap.to(videoContainer.get(0), { duration: 0.3, opacity: 0, clearProps: 'all' });
        $el.removeClass('is-playing');
    };
    
    const keyup = e => {
        const key = e.keyCode || e.which;
        if (key === 27) {
            controller.pause();
            close();
        }
    };

    const onClose = e => {
        e.preventDefault();
        controller.pause();
        close();
    };

    const onPlay = () => {
        gsap.killTweensOf(link.get(0));
        document.addEventListener('keyup', keyup);
        gsap.set(videoContainer.get(0), { display: 'block', opacity: 0 });
        gsap.to(videoContainer.get(0), { duration: 0.3, opacity: 1 });
        $el.addClass('is-playing');
    };

    const onStateChange = status => {

        if (status === controller.PLAY && !$el.hasClass('is-playing')) {
            //onPlay();
        } /* else if (status === controller.PAUSE && $el.hasClass('is-playing')) {
            document.removeEventListener('keyup', keyup);
            TweenMax.to(videoContainer.get(0), 0.3, { opacity: 0, clearProps: 'all' });
            $el.removeClass('is-playing');
        } */
    };

    const checkAndPlay = () => {
        if (!videoInited) {
            videoInited = true;
            videoContainer.html(props.embedCode);
            controller.init(videoContainer.find('iframe'), true);
            onPlay();
        } else {
            controller.play();
            onPlay();
        }
    };

    const onClick = e => {
        e.preventDefault();
        checkAndPlay();
    };

    const init = () => {
        controller = new VideoController(onStateChange);
        $el.find('[data-link]').on('click', onClick);
    };

    const destroy = () => {
        $el.off('click');
        controller.destroy();
    };

    return {
        init,
        destroy
    };
};
