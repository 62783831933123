import './lib/polyfills';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import gsap from 'gsap';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import parallaxer from './modules/Parallaxer';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();
    parallaxer.init();
    
    /*
    barba.init({
      // ...
    });
    *
     */
};

require('doc-ready')(init);
