import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default class VideoLoop {

    constructor(el, props = {}) {
        this.$el = $(el);

        this.$video = this.$el.is('video') ? this.$el : this.$el.find('video');
        this.video = this.$video.get(0);

        if (!this.video) {
            console.warn('No video');
            this.cantPlayVideo();
            return;
        }

        this.$video.css({ position: 'absolute', maxWidth: 'none' });
        
        this.videoIsPlaying = false;
        this.videoFallbackTimer = null;
        this.hideImageTimer = null;
        this.hasFadedIn = false;
        this.videoAspectRatio = 16/9;

        this.sources = {
            landscape: props.sources ? props.sources.desktop || props.sources.mobile || null : null,
            portrait: props.sources ? props.sources.mobile || props.sources.desktop || null : null
        };

        if (!this.sources.landscape && !this.sources.portrait) {
            console.warn('No sources');
            this.cantPlayVideo();
            return;
        }

        this.onLoadStart = this.onLoadStart.bind(this);
        this.onTimeUpdate = this.onTimeUpdate.bind(this);
        this.onFallbackTimer = this.onFallbackTimer.bind(this);
        this.onResize = this.onResize.bind(this);

        this.init();
    }

    getVideoFormat() {
        return this.$el.height() / this.$el.width() > 1 ? 'portrait' : 'landscape';
    }

    init() {
        //console.log('init');

        if (!this.video.canPlayType || !this.video.canPlayType('video/mp4')) {
            this.cantPlayVideo();
            return;
        }
        
        this.maybeSwapSource();
        //this.playAndCatch();
        this.$video.css('opacity', 0);

        Viewport.on('resize', this.onResize);
    }

    destroy() {
        if (this.videoIsPlaying) {
            this.$el.find('[data-image]').css({ visibility: '' });
        }
        if (this.hideImageTimer) {
            clearTimeout(this.hideImageTimer);
            this.hideImageTimer = null;
        }
        this.killVideo();
        this.$el.css({ visiblity: '' }).removeClass('js-is-playing js-cant-play-video');
    }

    stopFallbackTimer() {
        if (!this.video || !this.videoFallbackTimer) {
            return;
        }
        
        clearTimeout(this.videoFallbackTimer);
        this.videoFallbackTimer = null;
    }

    startFallbackTimer(interval) {
        this.stopFallbackTimer();
        this.videoFallbackTimer = setTimeout(this.onFallbackTimer, interval);
    }

    addVideoEventListeners() {
        if (!this.video) {
            return;
        }
        this.video.addEventListener('timeupdate', this.onTimeUpdate);
        this.video.addEventListener('loadstart', this.onLoadStart);
        this.video.addEventListener('loadedmetadata', this.onLoadStart);
        this.video.addEventListener('loadeddata', this.onLoadStart);
        this.video.addEventListener('canplay', this.onLoadStart);
    }

    removeVideoEventListeners() {
        if (!this.video) {
            return;
        }
        this.video.removeEventListener('timeupdate', this.onTimeUpdate);
        this.video.removeEventListener('loadstart', this.onLoadStart);
        this.video.removeEventListener('loadedmetadata', this.onLoadStart);
        this.video.removeEventListener('loadeddata', this.onLoadStart);
        this.video.removeEventListener('canplay', this.onLoadStart);
    }

    killVideo() {
        this.stopFallbackTimer();
        this.removeVideoEventListeners();
        if (!this.video) {
            return;
        }
        this.video.pause();
        this.videoIsPlaying = false;
    }

    maybeSwapSource() {
        if (!this.video) {
            return;
        }

        const currentFormat = this.getVideoFormat();
        const currentSource = this.video.getAttribute('src');
        const wantedSource = this.sources[currentFormat];

        if (!wantedSource || currentSource === wantedSource) {
            return;
        }

        this.killVideo();
        this.video.src = wantedSource;

        this.playAndCatch();
    }

    playAndCatch() {
        this.addVideoEventListeners();

        this.video.muted = true;

        this.startFallbackTimer(1000);

        const promise = this.video.play();

        if (promise !== undefined) {
            promise.then(() => {
                this.stopFallbackTimer();
            }).catch(e => {
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    this.cantPlayVideo();
                }
            });
        }
    }

    cantPlayVideo() {
        this.killVideo();
        this.$el.addClass('js-cant-play-video');
        this.$video.css({
            visiblity: 'hidden'
        });
        const $image = this.$el.find('[data-image]');
        if ($image.length && !$image.hasClass('lazyload')) {
            $image.addClass('lazyload');
        }
    }

    onFallbackTimer() {
        this.cantPlayVideo();
    }

    onResize() {
        this.maybeSwapSource();
        this.resizeVideo();
    }

    onVideoPlaying() {
        this.videoIsPlaying = true;
        this.resizeVideo();
        
        this.hideImageTimer = setTimeout(() => {
            this.$el.find('[data-image]').css({
                visibility: 'hidden'
            });
        }, 1000);
        
        requestAnimationFrame(() => {
            this.$el.addClass('js-is-playing');
        });
    }

    onTimeUpdate() {
        if (!this.video || this.video.currentTime < 0.001) {
            return;
        }
        
        this.removeVideoEventListeners();
        this.stopFallbackTimer();
        this.onVideoPlaying();
        
        if (!this.hasFadedIn) {
            gsap.to(this.$video.get(0), { duration: 0.5, opacity: 1 });
        }
        
        this.hasFadedIn = true;
    }

    onLoadStart() {
        this.resizeVideo();
        
        if (this.videoIsPlaying) {
            return;
        }
        
        this.startFallbackTimer(6000);
    }

    updateVideoAspectRatio() {
        this.$video.css({ width: '', height: '' });
        this.videoAspectRatio = this.$video.width() / this.$video.height();
    }
    
    resizeVideo() {
        const wrapperWidth = this.$el.width();
        const wrapperHeight = this.$el.height();
        const wrapperAspect = wrapperWidth / wrapperHeight;
        
        this.updateVideoAspectRatio();
        
        if (this.videoAspectRatio > wrapperAspect) {
            this.$video.css({ height: wrapperHeight + 'px', width: (wrapperHeight*this.videoAspectRatio) + 'px', top: 0, left: ((wrapperWidth-(wrapperHeight*this.videoAspectRatio))/2) + 'px' })
        } else {
            this.$video.css({ width: wrapperWidth + 'px', height: (wrapperWidth/this.videoAspectRatio) + 'px', left: 0, top: ((wrapperHeight-(wrapperWidth/this.videoAspectRatio))/2) + 'px' })
        }
        
    }
};
