import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

const loadPaper = require('bundle-loader?lazy&name=[name]!paper');

import * as eventKeys from '../lib/events';

export default (el, props) => {
    const $el = $(el);

    const { pathHeight: maxPathHeight = 0.85 } = props || {};

    const numPointsByBreakpoint = {
        min: 5,
        m: 5,
        mp: 6,
        l: 7,
        lp: 7,
        xl: 7
    };

    let numPoints = 0;

    // Get the color from the svg path
    const color = $el.css('color');

    // Init the canvas
    const $canvas = $el.find('canvas');
    const canvas = $canvas.get(0);
    canvas.hidden = false;

    let paper;
    let path;
    let width;
    let height;
    let center;
    let pathHeight;
    let seedConstant = 50;

    let isPlaying = false;
    let menuOpen = false;

    const getNumPoints = () => {
        const { name: breakpoint } = Viewport.breakpoint;
        return numPointsByBreakpoint[breakpoint] || numPointsByBreakpoint.min;
    };

    const drawPath = () => {
        numPoints = getNumPoints();

        center = paper.view.center;
        width = paper.view.size.width;
        height = paper.view.size.height / 2;
        path.segments = [];
        path.add(paper.view.bounds.topLeft);
        let x, y;
        
        for (let i = 1; i < numPoints; ++i) {
            if (i === 1) {
                x = width * 0.08;
                y = height * 0.75;
            } else if (i === numPoints - 1) {
                x = width * 0.95;
                y = height * 0.95;
            } else {
                x = (width * 0.085) + (width - (width * 0.01)) / (numPoints - 2) * (i - 1);
                y = center.y;
            }
            const point = new paper.Point(x, y);
            path.add(point);
        }
        
        path.add(paper.view.bounds.topRight);
        path.smooth({ type: 'continuous' });
    };

    const onScroll = e => {
        checkInViewport();
    };
    
    const onMenuBeforeOpen = e => {
        menuOpen = true;
        paper.view.onFrame = null;
        isPlaying = false;
    };

    const onMenuAfterClose = e => {
        menuOpen = false;
        checkInViewport();
    };

    const checkInViewport = () => {
        if (!menuOpen && paper) {
            if (!isPlaying && Viewport.visible($el.get(0), 100)) {
                paper.view.onFrame = paperFrameHandler;
                isPlaying = true;
            } else if (isPlaying && !Viewport.visible($el.get(0), 100)) {
                paper.view.onFrame = null;
                isPlaying = false;
            }
        }
    };

    const paperFrameHandler = event => {
        pathHeight = height * maxPathHeight;
        path.smooth({ type: 'continuous' });
        for (let i = 1; i < numPoints; ++i) {
            const sinSeed = event.count + (i + i % 10) * seedConstant;
            const sinHeight = Math.sin(sinSeed / 100) * pathHeight;
            if (i !== 1 && i !== numPoints - 1) {
                path.segments[i].point.y = Math.sin(sinSeed / 100) * sinHeight + height;
            }
        }
    };

    const init = () => {
        loadPaper(Paper => {
            paper = new Paper.PaperScope();
            paper.setup(canvas);
            path = new paper.Path();
            path.fillColor = color;
            
            seedConstant = 30 + Math.round(Math.random()*40);
    
            // Re-draw the path whenever the size changes
            paper.view.onResize = () => {
                drawPath();
            };
    
            drawPath();
            
            checkInViewport();
            //Dispatch.on(eventKeys.MENU_BEFORE_OPEN, onMenuBeforeOpen);
            //Dispatch.on(eventKeys.MENU_AFTER_CLOSE, onMenuAfterClose);
        });
        
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        //Dispatch.off(eventKeys.MENU_BEFORE_OPEN, onMenuBeforeOpen);
        //Dispatch.off(eventKeys.MENU_AFTER_CLOSE, onMenuAfterClose);

        if (paper) {
            paper.view.onFrame = null;
            paper.view.onResize = null;
            paper.remove();
            paper = null;
        }
    };

    return {
        init,
        destroy
    };

};
