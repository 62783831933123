import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default (el, props) => {
    const $el = $(el);
    const $iframe = $el.find('iframe');
    
    let timeoutInt = -1;

    const init = () => {
        $iframe.on('load', onResize);
        window.addEventListener('message', onReceive, false);
        Viewport.on('resize', onResize);
    };
    
    const destroy = () => {
        $iframe.off('load');
        window.removeEventListener('message', onReceive);
        Viewport.off('resize');
    };
    
    const onReceive = e => {
        $el.height(e.data);
    };
    
    const onResize = () => {
        clearTimeout(timeoutInt);
        
        timeoutInt = setTimeout(() => {
            $iframe.get(0).contentWindow.postMessage('Info', '*');
        }, 300);
    };

    return {
        init,
        destroy
    };
};
