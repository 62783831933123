import $ from '@vaersaagod/tools/Dom';
//import mapboxgl from 'mapbox-gl';
const loadMapbox = require('bundle-loader?lazy&name=[name]!mapbox-gl');

import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);

    const markers = props.markers;
    const apiKey = props.apiKey;

    const markerIcons = {
        hq: '<div style="width: 42px; height: 34px;" class="fill-orange-positive"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.1 34.1"><g><path class="st0" d="M16.6 34.1c-1 0-1.9-.4-2.7-1.1L1.1 20.2C.4 19.4 0 18.5 0 17.5c0-1 .4-1.9 1.1-2.7L13.9 2C15.3.6 17.8.6 19.2 2L32 14.8c.7.7 1.1 1.6 1.1 2.7s-.4 1.9-1.1 2.7L19.2 33c-.7.7-1.6 1.1-2.6 1.1zm0-30.2c-.2 0-.4.1-.5.2L3.2 17c-.3.3-.3.8 0 1.1L16 30.8c.2.2.4.2.5.2s.3 0 .5-.2L29.9 18c.2-.2.2-.4.2-.5s0-.3-.2-.5L17.1 4.2c-.1-.2-.3-.3-.5-.3z"/><path class="st0" d="M39.9 26c-.5 0-1.1-.2-1.5-.6L27.7 14.5c-.4-.4-.6-.9-.6-1.5s.2-1.1.6-1.5L38.4.6C39 0 40-.2 40.7.2c.8.3 1.3 1.1 1.3 2v21.7c0 .9-.5 1.6-1.3 2-.2 0-.5.1-.8.1zm-9.5-13l8.6 8.8V4.2L30.4 13zm-.6.6zm0-1.2z"/></g></svg></div>',
        default: '<div style="width: 33px; height: 33px;" class="fill-orange-positive"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.1 33.1"><g><path d="M16.6 33.1c-1 0-1.9-.4-2.7-1.1L1.1 19.2C.4 18.5 0 17.5 0 16.5c0-1 .4-1.9 1.1-2.7L13.9 1.1c1.4-1.4 3.9-1.4 5.3 0L32 13.9c.7.7 1.1 1.6 1.1 2.7s-.4 1.9-1.1 2.7L19.2 32c-.7.7-1.6 1.1-2.6 1.1zm0-30.1c-.2 0-.4 0-.6.2L3.2 16c-.3.3-.3.8 0 1.1L16 29.9c.2.2.4.2.5.2s.3 0 .5-.2l12.8-12.8c.2-.2.2-.4.2-.5s0-.3-.2-.5L17.1 3.2c-.1-.2-.3-.2-.5-.2z" fill="#ee8378"/></g></svg></div>'
    }

    const init = () => {
        loadMapbox(mapboxgl => {
            mapboxgl.accessToken = apiKey;

            const map = new mapboxgl.Map({
                container: el,
                scrollZoom: false,
                style: 'mapbox://styles/columbisalmon/ckh3giibh12d81amdyalq3wko',
                center: [0, 56],
                zoom: 3,
                attributionControl: false
            }).addControl(new mapboxgl.AttributionControl({
                compact: true
            }));

            var nav = new mapboxgl.NavigationControl();
            map.addControl(nav, 'top-right');

            markers.forEach((marker) => {
                var marker = new mapboxgl.Marker({
                    element: $(marker.headquarter ? markerIcons.hq : markerIcons.default).get(0)
                })
                    .setLngLat([marker.location.lng, marker.location.lat])
                    .setPopup(new mapboxgl.Popup().setHTML('<div class="text-blue-dark">' + marker.popupContent + '</div>'))
                    .addTo(map);
            });
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
