import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';

import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);
    const $select = $el.find('[data-custom-select]');
    const $categoryFilter = $el.find('[data-category-filter]');
    const $resultsWrapper = $el.find('[data-filter-results-wrapper]');
    
    const endpoint = props.endpoint;
    
    const init = () => {
        $select.on('change', e => {
            update();
        });
        
        $categoryFilter.on('click', e => {
            e.preventDefault();
            const $trigger = $(e.triggerTarget);
            $categoryFilter.removeClass('-selected');
            $trigger.addClass('-selected');
            update();
        });
    };
    
    const update = () => {
        const category = $categoryFilter.filter('.-selected').data('category-filter');
        const year = $select.get(0).options[$select.get(0).selectedIndex].value;
        
        gsap.to($resultsWrapper.nodes, { duration: 0.1, opacity: 0 });
        
        request
           .get(endpoint)
           .query({ category: category, year: year })
           .then(res => {
               if (res.statusCode === 200) {
                   parseData(res.text);
               } else {

               }
           });
    };
    
    const parseData = data => {
        const $data = $(data);
        const $results = $data.find('[data-filter-results-wrapper] > *');
        
        setTimeout(() => {
            $resultsWrapper.empty().append($results);
            gsap.to($resultsWrapper.nodes, { duration: 0.2, opacity: 1 });
        }, 100);
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
