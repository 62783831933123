import $ from '@vaersaagod/tools/Dom';
import Viewport from "@vaersaagod/tools/Viewport";


export default (el, props) => {

    const $el = $(el);
    const $fade = $el.find('[data-fade]');
    const $scroller = $el.find('[data-scroller]');
    
    const init = () => {
        $scroller.on('scroll', onScroll);
        onScroll();
        
        Viewport.on('resize', onResize);
    };
    
    const onScroll = () => {
        if ($scroller.width() + $scroller.get(0).scrollLeft > $scroller.get(0).scrollWidth - 10) {
            $fade.css({ opacity: 0 });
        } else {
            $fade.css({ opacity: 1 });
        }
    };
    
    const onResize = () => {
        onScroll();
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
