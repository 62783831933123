import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';
import { isEmail } from '@vaersaagod/tools/validation';

import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $form = $el.find('[data-form]');
    const $emailField = $el.find('input[name="newsletterEmail"]');
    const $emailFieldError = $el.find('[data-email-field-error]');
    const $confirmField = $el.find('input[name="newsletterConfirm"]');
    const $successWrap = $el.find('[data-newsletter-subscribe-success]');
    const $errorWrap = $el.find('[data-newsletter-subscribe-error]');
    const $errorMessage = $el.find('[data-newsletter-subscribe-error-message]');

    const endpoint = props.endpoint;
    const invalidEmailMessage = props.invalidEmailMessage;

    const init = () => {
        $form.on('submit', e => {
            e.preventDefault();
            process();
        });
    };

    const process = () => {
        let hasError = false;

        $emailFieldError.text('');
        $confirmField.parent().removeClass('theme-color-salmon');

        if (!isEmail($emailField.val())) {
            $emailFieldError.text(invalidEmailMessage);
            hasError = true;
        }

        if (!$confirmField.get(0).checked) {
            $confirmField.parent().addClass('theme-color-salmon');
            hasError = true;
        }

        if (hasError) return;

        let data = { newsletterEmail: $emailField.val(), newsletterConfirm: 'on' };
        data[window.csrfTokenName] = window.csrfTokenValue;

        request
            .post(endpoint)
            .set('Accept', 'application/json')
            .send(data)
            .then(res => {
                parseResult(res);
            });
    };

    const parseResult = res => {
        if (res.statusCode === 200) {
            $form.addClass('hidden');
            $successWrap.removeClass('hidden');
            $emailField.val('');
        } else {
            $errorMessage.text(res.message);
            $errorWrap.removeClass('hidden');
        }
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
